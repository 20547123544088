<style>
  .container {
    line-height: 1.5;
  }
  li {
    margin-bottom: 16px;
  }
</style>

<head>
  <meta name="title" content="Instant Card Grading Privacy Policy" />
  <meta name="description" content="Instant Card Grading Privacy Policy" />
</head>

<div class="container">
  <h1>Privacy Policy</h1>
  <b>Last Updated: Nov 7, 2023</b>
  <div>
    <p>
      This Privacy Policy describes the practices of 12 Triangles LLC, Instant
      Card Grading, and their respective divisions and subsidiaries
      (collectively “
      <strong>12 Triangles</strong>
      ”, “
      <strong>we</strong>
      ”, or, “
      <strong>us</strong>
      ”) with respect to personal information we collect in connection with our
      websites, mobile applications, or from any other interaction (such as
      emails, newsletters, or forms) where we display or link to this Privacy
      Policy (collectively referred to as our “
      <strong>Services</strong>
      ”). By accessing or otherwise using our Services, you consent to the terms
      of this Privacy Policy.
    </p>
    <p>
      <strong>Information We Collect:</strong>
      We collect personal information that varies depending on how you interact
      with us. Below we describe the types of personal information we may
      collect and the sources from which we may collect them.:
    </p>
    <ol>
      <li>
        Data collected automatically: When you use our Services, certain
        information about your device and web browser are automatically
        collected in connection with providing the Services to you, such as your
        IP address, device type, and operating system. This data is collected
        directly from your interaction with our Services.
      </li>
      <li>
        Usage information: We may collect information about how you use and
        engage with our Services, such as searches you conduct within our
        Services, how long you spend on specific pages, information or features
        you engage with, customizations or preferences you request or create,
        and other activity you engage in while using our Services. We collect
        this information directly from you and/or your device or browser.
      </li>
      <li>
        Information collected by cookies: Our Services may use cookies or other
        similar technologies. These technologies may collect information about
        your device, your browser, and your use of and activity within our
        Services. The data collected by cookies may include the “Data Collected
        Automatically” and “Usage Information” described above. This information
        may be collected directly from your device or browser. For more
        information on the cookies and similar technologies we use, please see
        our Cookie Statement below.
      </li>
      <li>
        Communications between you, us, and others: We may collect information
        about you in connection with requests or inquiries you make to us,
        whether done by email, phone, online submission, or otherwise. Our
        Services may also enable you to communicate with others, in which case
        we may receive a copy of messages you send to others.
      </li>
    </ol>
    <p>
      We may combine the information you provide to us with information that we
      collect through automated methods, and with information we receive from
      other sources. For example, when you add a method of payment to your
      account, we may combine your profile with information we receive from our
      payment processors or other providers regarding transactions made with the
      same payment credentials.
    </p>
    <p />
    <p>
      <strong>How We Use Information:</strong>
      We process personal information for the following purposes and – to the
      extent applicable – on the following legal bases:
    </p>
    <ul>
      <li>
        Analytics, improvement, and product/service development: We may use
        personal information to analyze, evaluate, and improve our Services, our
        technologies, and our business; to develop new technologies, products,
        and services; learn about our users; conduct research; evaluate user
        engagement with our Services; and facilitate the functionality and new
        functionalities in our Services. The legal basis for this processing is
        our legitimate business interests.
      </li>
      <li>
        Marketing: We use personal information to market to you and others
        regarding matters that may be in your interest, including via sending
        you direct marketing, and/or by delivering targeted and interest-based
        marketing within our Services and elsewhere, each as permitted by law.
        You can opt-out of marketing emails at any time by clicking the
        “Unsubscribe” link in any such email, or by emailing us as set forth
        below in the “Contact Us” section. The legal basis for this processing
        is your consent or our legitimate interests, as applicable.
      </li>
      <li>
        Protection of interests, security and integrity: Personal information
        may be used to protect the health, safety, welfare, rights, or interests
        of you, us, any third party, or the general public. It can also be used
        to protect the safety and integrity of our Services, troubleshoot
        issues, and detect and prevent errors, fraudulent activity, or other
        potentially wrongful, harmful, or illegal activity. The legal bases for
        this processing are our compliance with law, the protection of
        individuals’ vital interests, and the legitimate interests of us or of
        third parties, as applicable.
      </li>
      <li>
        Compliance and claims: We may use personal information to comply with,
        establish, assert, exercise, defend against, or enforce any legal
        claims, legal rights, legal obligations, legal process, government
        requests, 12 Triangles policies, internal investigations, or prudent
        security or business practices. This includes (but is not limited to)
        enforcing this Privacy Policy, our
        <a href="https://www.instantcardgrading.com/#/terms">User Agreement</a>
        , and any other contract(s) or agreement(s) we may have with you. The
        legal bases for this processing are our compliance with law and the
        legitimate interests of us or of third parties, as applicable.
      </li>
      <li>
        As otherwise notified or permitted: We may also use information we
        collect for any other purpose notified to you or to which you consent,
        or which is permitted by applicable law.
      </li>
    </ul>
    <p>
      <strong>How We Share Information:</strong>
      We may share information with third parties for any purpose described in
      Section 2 above (“
      <strong>How We Use Information</strong>
      ”), or for any other purpose that has been notified to you or to which you
      have consented. This generally includes sharing information with the
      following categories of recipients:
    </p>
    <ul>
      <li>
        Affiliates: We may share personal information with 12 Triangles’
        affiliates.
      </li>
      <li>
        Service providers: We may share information with service providers and
        business partners who provide services to us or otherwise help us
        operate our business or the Services, such as (without limitation) by
        hosting data or our Services, helping us send newsletters, processing
        payments, and detecting and preventing payment and other fraud.
      </li>
      <li>
        Other collectors &amp; users, or the general public: Our Services may
        enable you to share information about your collectibles with other
        collectors or the public, or to offer your collectibles for sale, such
        as by (a) posting information about your collectibles on our Services,
        and/or (b) listing collectibles for sale via a publicly-accessible
        marketplace. In such cases, as applicable, your information (including
        information you provide about your collectible(s)) may be accessible to
        other collectors or to the general public. Similarly, if you post about,
        bid on, or purchase a collectible, your information may became publicly
        available, and/or may be shared with the seller of the collectible.
        Lastly, if you participate in our user fora and/or message boards,
        anything you post may be viewed by other users or the public, as
        applicable.
      </li>
      <li>
        Event-related sharing: If you register for an event we organize, we may
        share your personal information in connection with enabling your
        participation in the event, such as (for instance) by sharing your name
        with the facility where the event is occurring. Your information may, to
        the extent permitted by law, be shared with other event participants.
      </li>
      <li>
        Compliance- and security-related transfers: We may share personal
        information with third parties in connection with (a) complying with,
        establishing, asserting, exercising, defending against, or enforcing any
        legal claims, legal rights, legal obligations, legal process, 12
        Triangles policies, government requests, or prudent security or business
        practices; (b) protecting the health, safety, welfare, rights, or
        interests of you, us, any third party, or the general public; or (c)
        protecting the safety and integrity of our Services, troubleshooting
        issues, and detecting and preventing errors, fraudulent activity, or
        other potentially wrongful, harmful, or illegal activity. This may
        include disclosures to other collectors, insurance companies, law
        enforcement, third-party owners, or others.
      </li>
      <li>
        <strong>Security. 12 Triangles</strong>
        takes measures to secure personal information we collect. However,
        please note that data that is transported over an open network, such as
        the Internet or e-mail, may be accessible to anybody. When disclosing
        any personal information via an open network, you should remain mindful
        of the fact that it is potentially accessible to others, and
        consequently, can be collected and used by others without your consent.
        We cannot guarantee the confidentiality of any communication or material
        transmitted via such open networks. Please use other means of
        communication if you think this is necessary or prudent for security
        reasons.
      </li>
      <li>
        <strong>Retention of Personal Information:</strong>
        We generally retain personal information for as long as is necessary to
        perform the purposes for which it was collected, as outlined in this
        Privacy Policy, unless applicable law permits or requires a longer
        period. Please note that this means that, in some cases, we may be
        required to retain personal information after the end of our direct
        relationship with you. If you have questions about the period for which
        data may be retained, contact us as set forth in the section “Contact
        Us” below.
      </li>
      <li>
        <strong>Location of Services; Transfers:</strong>
        12 Triangles and the servers that provide our online Services are
        located in the United States. By using our online Services, you
        understand and agree that your personal information may be collected and
        stored in the United States
      </li>
      <li>
        <strong>Minors:</strong>
        Our Services are intended solely for use by persons 14 years of age or
        older. If you are under the age of 14, you may not use our Services.
      </li>
      <li>
        <strong>Your Marketing Choices:</strong>
        You can opt-out of receiving marketing emails or newsletters from us at
        any time by clicking the “Unsubscribe” link in any such email, or by
        emailing us as set forth below in the “Contact Us” section. Please note
        that even if you opt out, we may still send communications to you about
        your transactions, any accounts you have with us, any request or inquiry
        you have made, or other non-marketing related matters.
      </li>
      <li>
        <strong>Interest-based Advertising.</strong>
        Our Services may work with third parties such as advertising networks to
        provide you with advertisements that you see as part of the Services, or
        on other websites or apps. These parties may use technology that can
        collect personal information about your online activities over time and
        across different websites when you use our Services.
        <strong />
        To learn more about your ability to opt out of certain of these
        technologies and for other information, you can visit the
        <a href="https://digitaladvertisingalliance.org/">
          Digital Advertising Alliance
        </a>
        and
        <a href="http://www.networkadvertising.org/">
          the Network Advertising Initiative.
        </a>
      </li>
      <li>
        <strong>Links to Other Sites:</strong>
        Our Services may contain links to other websites or platforms. These
        websites operate independently from us, and their privacy practices are
        subject to their own privacy notices or policies, which we strongly
        encourage you to review to understand their procedures for collecting,
        using and disclosing personally identifiable information. We are not
        responsible for the content of the linked websites, any use of these
        sites, or the privacy practices of these sites.
      </li>
    </ul>
    <ol>
      <li>
        <strong>California-Specific Disclosures</strong>
        : California residents can
        <a href="https://oag.ca.gov/privacy/ccpa">
          click here to view the California Consumer Privacy Act Notice
        </a>
        .
      </li>
      <li>
        <strong>Do Not Track Signals:</strong>
        As of the Effective Date listed below, there is no commonly accepted
        response to Do Not Track signals initiated by browsers. Therefore, our
        Services are not designed to respond to such signals.
      </li>
      <li>
        <strong>Changes to this Privacy Policy:</strong>
        We may modify this Privacy Policy from time to time, and will post any
        revisions on this website. We will indicate at the bottom of the Privacy
        Policy the Effective Date of the most recent update. By using our
        Services following an update, you accept and agree to this Privacy
        Policy in its updated form.
      </li>
      <li>
        <strong>Contact Us:</strong>
        If you have any questions about this Privacy Policy or otherwise, please
        contact us by:
      </li>
    </ol>
    <p>
      Emailing us at
      <a href="mailto:hello@12Triangles.com">hello@12Triangles.com</a>
    </p>
    <p />
    <p>Effective Date: November 8th, 2023</p>
  </div>
</div>
