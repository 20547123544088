// @ts-nocheck

// import { FirebaseApp, User, Doc, Collection } from 'sveltefire'

// import firebase from 'firebase/app'
// import 'firebase/firestore'
// import 'firebase/auth';
// import 'firebase/performance';
// import 'firebase/analytics';
// import 'firebase/firestore'

import { initializeApp } from 'firebase/app'
import { getFirestore } from 'firebase/firestore'
// import { getStorage } from 'firebase/storage'
import { getAuth } from 'firebase/auth'

let firebaseConfig = {
  apiKey: 'AIzaSyCWLUI2wExGsoKXru_HJTL84iCwIjHNpi8',
  authDomain: 'instantcardgrading.firebaseapp.com',
  projectId: 'instantcardgrading',
  storageBucket: 'instantcardgrading.appspot.com',
  messagingSenderId: '381966984395',
  appId: '1:381966984395:web:176c26431b1603ff44274e',
  measurementId: 'G-XKCSW6DYP4',
}

// Initialize Firebase
const app = initializeApp(firebaseConfig)
export const firestore = getFirestore(app)
export const storage = undefined //getStorage(app)
export const auth = getAuth(app)

// firebase.initializeApp(firebaseConfig);
