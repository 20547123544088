<script>
  import { onMount } from 'svelte'

  onMount(() => {
    window.scrollTo(0, 0)
    let title = window.location.href
  })
</script>

<style>
  h1 {
    font-size: 24px;
  }

  @media (min-width: 1024px) {
    h1 {
      font-size: 34px;
    }
  }

  h1 {
    font-size: 24px;
  }

  @media (min-width: 1024px) {
    h1 {
      font-size: 34px;
    }
  }
  .hero {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-self: center;
    width: 100%;
    /* height: 160%; */
    /* height: 120%; */
    margin: auto;
    text-align: center;
  }

  .bolt {
    width: 200px;
    max-width: 41%;
    margin: 48px auto 16px;
  }

  .app-stores {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    max-width: 400px;
    margin: 32px auto 0px;
  }

  .title {
    margin-bottom: 4px;
    /* font-size: 32px; */
  }

  .subtitle {
    margin: 0px 8px;
  }

  .link {
    margin: 0px;
  }

  .store {
    min-width: 180px;
    max-width: 180px;
    margin: 0px auto 8px;
  }

  .screenshots {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
    width: 100%;
    height: auto;
    display: block;
  }

  .screen {
    width: 360px;
    max-width: 41%;
    margin: auto;
    padding-top: 48px;
    padding-bottom: 16px;
  }
</style>

<head>
  <meta charset="utf-8" />
  <meta name="viewport" content="width=device-width,initial-scale=1" />

  <title>Instant Card Grading! ⚡️</title>

  <meta property="og:site_name" content="Instant Card Grading" />
  <meta property="og:title" content="Instant Card Grading!" />
  <meta
    property="og:description"
    content="Quickly center grade your sports and trading cards!" />
  <meta property="og:url" content="https://instantcardgrading.com" />
  <meta property="og:type" content="website" />
  <meta property="og:image" content="assets/ogImage.png" />
  <meta property="og:image:secure_url" content="assets/ogImage.png" />
  <meta property="og:image:width" content="1280" />
  <meta property="og:image:height" content="640" />
  <meta property="twitter:card" content="summary_large_image" />
  <meta property="twitter:image" content="assets/ogImage.png" />
  <meta property="twitter:site" content="@instantgrading" />
  <meta
    name="facebook-domain-verification"
    content="ztrnbsk0pp45n71yibyo52pqprm08c" />
  <meta property="fb:app_id" content="704326351282559" />

</head>
<div class="hero">
  <img class="bolt" src="assets/bigBoltWeb.png" alt="" />
  <h1 class="title">INSTANT CARD GRADING!</h1>
  <h2 class="subtitle">Quickly center grade your sports and trading cards!</h2>

  <div class="app-stores">
    <a
      class="link"
      href="https://apps.apple.com/us/app/instant-card-grading/id6469464438">
      <img
        class="store"
        src="assets/app-store.png"
        alt="Download Instant Card Grading on iOS" />
    </a>
    <br />
    <a
      class="link"
      href="https://play.google.com/store/apps/details?id=com.triangles12.icg">
      <img
        class="store"
        src="assets/google-play.png"
        alt="Download Instant Card Grading on Android" />
    </a>
  </div>
  <div class="screenshots">
    <picture>
      <source srcset="assets/step1.webp" type="image/webp" />
      <source srcset="assets/step1.png" type="image/png" />
      <img
        class="screen"
        src="step1.png"
        alt="Center your card inside the edges of the guide" />
    </picture>
    <picture>
      <source srcset="assets/step2.webp" type="image/webp" />
      <source srcset="assets/step2.png" type="image/png" />
      <img
        class="screen"
        src="step2.png"
        alt="Adjust dashed lines to measure center grade" />
    </picture>
    <picture>
      <source srcset="assets/step3.webp" type="image/webp" />
      <source srcset="assets/step3.png" type="image/png" />
      <img
        class="screen"
        src="step3.png"
        alt="Enter your card's name and collection" />
    </picture>
  </div>
</div>

<div class="app-stores">
  <a
    class="link"
    href="https://apps.apple.com/us/app/instant-card-grading/id6469464438">
    <img
      class="store"
      src="assets/app-store.png"
      alt="Download Instant Card Grading on iOS" />
  </a>
  <br />
  <a
    class="link"
    href="https://play.google.com/store/apps/details?id=com.triangles12.icg">
    <img
      class="store"
      src="assets/google-play.png"
      alt="Download Instant Card Grading on Android" />
  </a>
</div>
