<style>
  .table {
    border: 1px solid #b0b7bc;
    margin-bottom: 20px;
    margin: auto;
    max-width: 500px;
  }

  .table-heading {
    background: #021021;
    color: #fff;
    border: 1px solid #021021;
    font-weight: bold;
  }

  ul {
    display: flex;
    list-style-type: none; /* Remove bullets */
    padding: 0; /* Remove padding */
    margin: 0; /* Remove margins */
    border: 1px solid #b0b7bc;
  }

  li {
    width: 20%;
    text-align: center;
    height: 48px;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  .description {
    /* text-align: left; */
    width: 96%;
    max-width: 500px;
    margin: auto;
  }
  .container {
    display: flex;
    flex-direction: column;
    text-align: center;
  }

  h3 {
    text-align: center;
  }

  .app-stores {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    max-width: 400px;
    margin: 32px auto 0px auto;
  }

  .link {
    margin: 0px;
  }
  .store {
    min-width: 180px;
    max-width: 180px;
    margin: 0px auto 8px;
  }
</style>

<div class="container">
  <h1>Instant Card Grading</h1>
  <h2>How is a center grade calculated?</h2>

  <div class="description">
    <h3>L / (L + R) + R / (L + R)</h3>
    Represented as a percentage, centering is calculated by combining the widths
    of the front left border and front right border of the card and dividing
    each side by the left and right total.
    <br />
    <br />
    <h3>T / (T + B) + B / (T + B)</h3>
    Then, we combine the heights of both the front top border and front bottom
    border of the card and divide by the top and bottom total.
    <br />
    <br />
    <h3>/ 2</h3>
    Finally, we convert our left-right and top-bottom percentages to a grade
    using the table below and average the two grades together, rounding down for
    a final card grade!
  </div>
  <br />
  <h2>How do we grade cards?</h2>
  <br />
  <div class="graph">
    <div class="table">
      <ul class="table-heading">
        <li>GRADE</li>
        <li>ICG</li>
        <li>PSA</li>
        <li>SCG</li>
        <li>BGS</li>
      </ul>

      <ul>
        <li>10</li>
        <li>55 | 45</li>
        <li>60 | 40</li>
        <li>55 | 45</li>
        <li>50 | 50</li>

      </ul>

      <ul>
        <li>9</li>
        <li>60 | 40</li>
        <li>65 | 35</li>
        <li>60 | 40</li>
        <li>55 | 45</li>
      </ul>

      <ul>
        <li>8</li>
        <li>65 | 35</li>
        <li>70 | 30</li>
        <li>65 | 35</li>
        <li>60 | 40</li>
      </ul>

      <ul>
        <li>7</li>
        <li>70 | 30</li>
        <li>75 | 25</li>
        <li>70 | 30</li>
        <li>65 | 35</li>
      </ul>
    </div>
    <br />
  </div>
  🚧 Under Construction 🚧
  <div />
</div>
<div class="app-stores">
  <a
    class="link"
    href="https://apps.apple.com/us/app/instant-card-grading/id6469464438">
    <img
      class="store"
      src="assets/app-store.png"
      alt="Download Instant Card Grading on iOS" />
  </a>
  <br />
  <a
    class="link"
    href="https://play.google.com/store/apps/details?id=com.triangles12.icg">
    <img
      class="store"
      src="assets/google-play.png"
      alt="Download Instant Card Grading on Android" />
  </a>
</div>
