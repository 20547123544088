<style>
  .container {
    line-height: 1.5;
  }

  @media (max-width: 1024px) {
    ol {
      padding-left: 16px;
    }
  }
</style>

<head>
  <meta name="title" content="Instant Card Grading User Agreement" />
  <meta name="description" content="Instant Card Grading User Agreement" />
</head>

<div class="container">
  <div>
    <h1>User Agreement</h1>
    <b>Last Updated: Nov 8, 2023</b>
    <p>
      12 Triangles LLC, through its various divisions and affiliate entities (as
      further identified below as a 12 Triangles) (collectively and individually
      referred to herein as, “we” or “12 Triangles”) maintains websites,
      services, products, applications, and tools related to the grading of
      collectible items (collectively referred to as the “Services”). Each
      Service is provided subject to the terms and conditions and instructions
      applicable to the specific Service (for each Service, the “Terms of
      Service” is incorporated herein by this reference) and the service fees,
      prices, rates, and charges provided for that Service, as well as the terms
      and conditions provided in this User Agreement (collectively with the
      Terms of Service, the “Terms”). In the event of any inconsistencies
      between the terms of this User Agreement and the Terms of Service for a
      specific Service, the terms of this User Agreement will control.
    </p>
    <p>
      Services are provided by the following divisions and affiliates of 12
      Triangles LLC, including its division: Instant Card Grading (ICG) (a “12
      Triangles Entity”). These Terms constitute a binding legal agreement
      between you and each of the foregoing 12 Triangles Entities.
    </p>
    <p>
      <b>
        BEFORE USING ANY 12 TRIANGLES SERVICE, YOU SHOULD CAREFULLY READ THIS
        USER AGREEMENT AND THE APPLICABLE TERMS OF SERVICE.
      </b>
      By using any Services, you agree that your Account (as defined below) is
      governed by the Terms and you agree to be bound by the Terms. If you do
      not agree to the Terms, then you should use the applicable Services.
      Should you object to any of the Terms, or any subsequent modifications, or
      become dissatisfied with any Services, your sole remedy is to discontinue
      use of the applicable Services.
    </p>
    <p>
      <b>
        PLEASE BE ADVISED THAT THIS USER AGREEMENT CONTAINS PROVISIONS THAT
        GOVERN HOW CLAIMS YOU AND WE HAVE AGAINST EACH OTHER ARE RESOLVED. READ
        THESE TERMS CAREFULLY BECAUSE THEY CONTAIN: (I) LIMITATIONS OF LIABILITY
        AND DISCLAIMERS REGARDING RISK OF LOSS AND DAMAGES; AND (II) A PROVISION
        REQUIRING ARBITRATION OF DISPUTES BETWEEN US, INSTEAD OF CLASS ACTIONS
        OR JURY TRIALS (See Section 12 below for the Dispute Resolution and
        Arbitration terms).
      </b>
    </p>
    <p>
      We reserve the right to update and make changes to the Terms at any time
      by updating this page or providing notice to you electronically or as
      otherwise permitted under applicable law. We may also update and make
      changes to the terms applicable to each Service as further set forth in
      the applicable Terms of Service. Updates and changes take effect when we
      post them. Your continued access to or use any Services after any updates
      and/or changes indicates your acceptance to the Terms as modified. We may
      also ask you to acknowledge your acceptance of the User Agreement through
      an electronic click-through. It is your responsibility to periodically
      visit this page and review the Terms for updates.
    </p>
    <ol>
      <li>
        <p>
          <b>
            NO INVESTMENT ADVICE; INVESTMENT RISKS. No Content presented with
            any Services should be viewed as legal, tax, investment, financial,
            professional, or other advice.
          </b>
          Content available on the Services is information of a general nature
          and does not address the circumstances of any particular individual or
          entity or constitute a comprehensive or completed statement of the
          matters discussed or the law relating to such matters. 12 Triangles is
          not a fiduciary by virtue of any person’s use or access to the
          Services or any Content (as defined below). You alone assume the sole
          responsibility of evaluating the merits and risks associated with the
          use of any Content on the Services before making any decisions based
          on such Content.
          <br />
          <br />
          <b>
            There are risks associated with buying, selling, trading, holding,
            or otherwise engaging with collectible items. These risks include
            risk of loss. A collectibles' past financial performance is not a
            guarantee of future financial performance.
          </b>
        </p>
      </li>
      <li>
        <p>
          <b>ACCOUNT REGISTRATION.</b>
          To use the Services, an anonymous account will be created at the time
          that you first open the Instant Card Grading application (a
          “Account”). You are solely responsible for any communications,
          submissions, or other uses made using your Account, as well as for any
          obligations that may result from such use. We are not liable for any
          loss or damage arising from failure to comply with this provision or
          unauthorized use of your Account. If we believe the details are not
          correct, current, or complete, we have the right to refuse access to
          Services, or any of its resources and to terminate or suspend your
          Account.
          <br />
          <br />
          The Services are available to people who have reached the age of legal
          majority and can form legally binding agreements under applicable law
          and are otherwise eligible to open an account for Services. We must
          not have previously disabled your account or terminated Services with
          you for violation of any laws or any of our Terms or policies. WE DO
          NOT REPRESENT OR WARRANT THAT THE SERVICES OR ANY PART THEREOF ARE
          APPROPRIATE OR AVAILABLE FOR USE IN ANY PARTICULAR JURISDICTION OTHER
          THAN THE UNITED STATES. If you open an Account or obtain Services from
          outside the United States, you (i) do so at your own risk and are
          responsible for complying with the laws and regulations of your
          territory of residence in connection with the submission and (ii)
          understand that Terms and your use of the Services are governed by the
          laws as set forth in this User Agreement or the applicable Terms of
          Service.
          <br />
          <br />
          Accounts are personal, non-transferable, and limited to a single
          individual. There cannot be joint owners of an Account and only the
          individual authorized under the Account may request or obtain Services
          under that Account. If you create or use an Account on behalf of a
          business entity, you represent that you are authorized to act on
          behalf of such business and bind the business to this User Agreement.
          Such Account is owned and controlled by the business entity. We
          reserve the right to limit or modify account registration at any time
          and to accept or refuse registration at our discretion.
          <br />
          <br />
          Personal information you provide in connection with your Account or
          use of the Services is subject to the
          <span>
            <a
              href="https://instantcardgrading.com/#privacy"
              alt="Instant Card Grading Privacy Policy">
              Instant Card Grading Privacy Policy.
            </a>
          </span>
        </p>
      </li>
      <li>
        <p>
          <b>ACCESS TO SERVICES AND LIMITED RIGHT TO USE CONTENT.</b>
          Content included on the Services, including information, data,
          software, images photographs, videos, and other materials, including
          but not limited to Instant Card Grading and Card Facts (collectively
          “Content”) is protected by copyright, trademark, patent or other
          proprietary rights. 12 Triangles owns and retains rights, to the
          fullest extent allowed by such laws, the copyright in the selection,
          coordination, arrangement, and enhancement of such Content. Except as
          expressly authorized or licensed, you may not copy, modify, delete,
          publish, transmit, participate in the transfer or sale, lease or
          rental of, create derivative works from or in any way exploit any of
          the Content, in whole or in part. The software, applications,
          algorithms and technology used to provide the Services and
          functionality remain the exclusive property of 12 Triangles.
        </p>
        <p>
          We grant you a limited license to access and make informational,
          personal, and non-commercial use of the Services and Content (as
          defined above). You may view, use, and copy certain materials from the
          websites of 12 Triangles and its affiliates and Services in a manner
          consistent with the applicable Terms of Service for non–commercial,
          personal use, provided you retain all copyright, trademark and other
          proprietary notices contained in the Content, do not modify or alter
          the material, and do not copy or post the material on any network
          computer or broadcast the material in any media (other than as
          expressly permitted by the applicable Terms of Service). It is
          strictly prohibited to modify, transmit, distribute, reuse, repost,
          “frame” or use the Content for public or commercial purposes,
          including for purposes of minting or creating non-fungible tokens
          (other than as permitted in the applicable Terms of Service) without
          written permission from an authorized representative of 12 Triangles
          or its applicable affiliate entity.
          <br />
          <br />
          Trade names, trademarks, and service marks of Instant Card Grading,
          and associated goodwill, remain the property of 12 Triangles. All
          trademarks and service marks on the websites and Services not owned by
          12 Triangles are the property of their respective owners. Nothing
          contained on the websites or any Services should be construed as
          granting, by implication, estoppel, or otherwise, any license or right
          to use any of the trade name, trademarks, or service marks, without
          our express prior written consent.
        </p>
      </li>
      <li>
        <p>
          <b>ADS AND FEES.</b>
          We display ads for the use of our Services. These ads may be removed
          at anytime by purchasing the 'Remove Ads' digital product in the
          applicable service. The service fees, prices, rates, and charges we
          charge are listed with the applicable Services and exclude applicable
          taxes We may change our fees from time to time by posting the changes
          with the applicable Service in accordance with the Terms of Service,
          but may not provide advance notice for temporary promotions or any
          changes that result in the reduction of fees. IF YOU PURCHASE GOODS OR
          SERVICES, WE WILL CHARGE THE PAYMENT METHOD YOU PROVIDE. We anticipate
          authorizing and charging your payment method at the time of purchase
          for the value of your order plus any applicable taxes and shipping
          charges. You authorize us to charge your payment method when we bill
          you for the charges incurred by you.
        </p>
      </li>
      <li>
        <p>
          <b>INSTANT CARD GRADING APPLICATIONS.</b>
          In order to use Services made available through the mobile
          applications provided by us, you must have a compatible mobile device;
          we do not warrant that any applications will be compatible with your
          mobile device. If you access an application using an Apple iOS or
          Android powered device, Apple Inc. ("Apple") or Google, Inc.
          ("Google"), and their subsidiaries, respectively, shall be a
          third-party beneficiary to these Terms with respect to the
          applications. However, these third-party beneficiaries are not a party
          to these Terms and are not responsible for the provision or support of
          the applications. You agree that your access to the applications are
          also subject to the usage terms set forth in the applicable
          third-party beneficiary's terms of service.
          <br />
          <br />
          The applications may automatically download and install updates from
          time to time or require the downloading and installation of updates or
          new versions for continued use. You acknowledge that in some
          instances, updates and/or new versions may reduce or remove features
          and functionality in prior versions of the applications.
          <br />
          <br />
          You acknowledge that Apple and Google are not responsible for
          addressing any claims by you or any third party relating to the
          applications or your possession and/or use of the applications,
          including, but not limited to: (i) product liability claims; (ii) any
          claim that the application fails to conform to any applicable legal or
          regulatory requirement; and (iii) claims arising under consumer
          protection, privacy, or similar legislation. You further acknowledge
          that, in no event, shall Apple or Google be responsible for the
          investigation, defense, settlement and discharge of any third-party
          claim that the application infringes that third party's intellectual
          property rights.
        </p>
      </li>
      <li>
        <p>
          <b>PERMISSIONS YOU GRANT:</b>
          <br />
          <br />
          <b>Content.</b>
          By displaying, publishing or posting comments, text, images, photos,
          or other content that you submit for posting on any Services (“Your
          Content”), you grant to 12 Triangles, its affiliate entities, and
          those acting at their direction, a perpetual, irrevocable,
          royalty-free, worldwide, sublicenseable right and license to use,
          modify, reproduce, publicly perform, display, distribute and create
          derivative works based upon Your Content for any commercial and
          non-commercial purposes in any media, now known or hereafter created,
          as We may determine, in our sole discretion, without the need for any
          acknowledgment or compensation. If you save Your Content as part of
          the Services with your Account, we have the right to store Your
          Content for access by you at a later date. We may delete Your Content
          after a reasonable period of time consistent with our then-current
          policies or upon termination of your Account. Your Content may become
          inaccessible or deleted for other reasons and you should not rely on
          the Services for storage or archiving of Your Content. As a result, we
          suggest that you create back-up copies of Your Content.
          <br />
          <br />
          You are responsible for all Your Content. By posting Your Content, you
          represent and warrant that Your Content is your own original work or
          is being posted with the permission of the owner of that content and
          the content does not infringe or violate the copyright, trademark,
          trade secret or other personal or privacy rights of any person or
          entity. You must share only the photos and posts that you have the
          right to share. Please note that we reserve the right to remove
          content or information if we believe it violates the Terms.
        </p>
        <p>
          <b>Submissions.</b>
          We do not intend to accept ideas, concepts or techniques for new
          services or products through the Services. Notwithstanding the
          foregoing, any communication or material you submit to us, such as
          questions, comments, ideas, feedback, suggestions or the like
          (collectively “Submissions”), will be treated as non-confidential and
          nonproprietary and may be used by us for any purpose without notice to
          you and without any further consent by you. We will be under no
          obligation whatsoever to use, respond to, or pay any compensation with
          respect to any Submission. The act of providing any Submission
          constitutes an assignment to us of all worldwide right, title and
          interest, including copyright and other intellectual property rights,
          in the Submission This policy is intended to avoid the possibility of
          future misunderstandings when projects developed by us might seem to
          others to be similar to their own submissions or comments.
        </p>
      </li>
      <li>
        <p>
          <b>RULES OF USER CONDUCT.</b>
          You agree to access and use your Account and the Services and all
          Content in accordance with the Terms and all applicable laws. You
          agree to remain courteous and respectful toward our employees,
          contractors, agents, and representatives and our partners and other
          users of the Services. Your access to and use of your Account and the
          Services is conditioned on your compliance with the rules of conduct
          set forth below. You agree not to:
        </p>
        <ol>
          <li>
            <p>
              use the Services or Content for any unlawful, fraudulent, or
              malicious purposes;
            </p>
          </li>
          <li>
            <p>
              manipulate the price of any item or interfere with any other
              user's listings;
            </p>
          </li>
          <li>
            <p>
              interfere with or attempt to disrupt the operation of the
              Services, including, without limitation, any act that could
              disrupt, damage, disable, overburden, or impair the systems,
              servers, or networks used to make the Services available; or
              violate any requirements, procedures, policies or regulations of
              such networks;
            </p>
          </li>
          <li>
            <p>
              circumvent or attempt to circumvent any security or authentication
              measures implemented by us or on our behalf;
            </p>
          </li>
          <li>
            <p>
              modify, adapt, translate, or reverse engineer any portion of the
              Services or Content;
            </p>
          </li>
          <li>
            <p>
              attempt to gain unauthorized access to any accounts, features,
              systems, or networks through hacking, password mining, or any
              other means;
            </p>
          </li>
          <li>
            <p>
              use Services or Content for advertisements, chain letters,
              spamming, junk mail, solicitations, or any other commercial
              purposes, without our express prior written consent;
            </p>
          </li>
          <li>
            <p>
              frame or mirror any portion or feature of the Services or Content,
              or commercialize any Instant Card Grading application or any
              Content associated with such application;
            </p>
          </li>
          <li>
            <p>
              use any “deep-link”, “page-scrape”, “robot”, “spider”, or other
              automatic device, program, algorithm, or methodology, or any
              similar or equivalent manual process, to: (1) retrieve, scrape,
              access, acquire, copy, or monitor any portion of the Services or
              Content; (2) reproduce or circumvent the navigational structure or
              presentation of the Services or Content; or (3) data mine, obtain,
              or attempt to obtain any materials, documents, or information
              through any means not purposely made available through the
              Services;
            </p>
          </li>
          <li>
            <p>
              transmit any viruses, worms, defects, Trojan horses, time bombs,
              cancel-bots, corrupted files, or other items of a destructive
              nature;
            </p>
          </li>
          <li>
            <p>forge headers or otherwise manipulate identifiers;</p>
          </li>
          <li>
            <p>
              post, transmit, submit, or include any unlawful, harmful,
              threatening, abusive, harassing, defamatory, vulgar, obscene,
              sexually explicit, profane, hateful, racially, ethnically, or
              otherwise objectionable content, material, or information of any
              kind, or any content, material, or information that may give rise
              to criminal or civil liability; and/or violate, infringe, or
              misappropriate the intellectual property, publicity, privacy, or
              other proprietary rights of 12 Triangles, its affiliates, or any
              other person or entity. We reserve the right to prohibit access,
              use, conduct, communications, or content that we, in our sole
              discretion, deem to be harmful to us, the Services, Content, our
              users, our brand, or any other person or entity, or that violates
              the Terms and/or applicable law. We shall be the sole arbiter in
              cases of suspected abuse, fraud, or violations of the Terms, and
              any decision we make relating to termination or disabling of any
              Account or access to and use of Services shall be final and
              binding.
            </p>
          </li>
        </ol>
      </li>
      <li>
        <p>
          <b>PROMOTIONS.</b>
          Any programs, offers, discounts, sweepstakes, contests, surveys or
          other similar promotions (collectively, "Promotions") made available
          through the Services may be governed by rules and/or terms that are
          additional to the Terms. By participating in any such Promotion, you
          will become subject to any such additional Promotion terms. We urge
          you to read the applicable Promotion terms prior to participating in
          any Promotion.
        </p>
      </li>
      <li>
        <p>
          <b>CHANGES TO THE SERVICES.</b>
          We may from time to time, without any notice or obligation to users,
          at our sole discretion (i) make changes, delete, adapt, or make
          available additional features on the Services; (ii) interrupt the
          operation of the Services, or any portion thereof, as necessary to
          perform routine or non-routine maintenance, error correction, or for
          any other purposes; or (iii) impose limits on certain programs,
          features, and services, or restrict your access to the Services, in
          whole or in part.
        </p>
      </li>
      <li>
        <p>
          <b>TERMINATION / ACCOUNT SUSPENSION / ACCESS RESTRICTION.</b>
          We may limit, suspend or terminate, at our sole and absolute
          discretion, any Account (in whole or with respect to a particular
          Service) or access to Services immediately if we believe the Account
          holder has acted inconsistently with the Terms or the law or acted in
          a manner harmful to us. We may further, in our sole discretion and
          without limiting other remedies, delay or remove hosted content;
          remove any special status associated with your Account(s); remove and
          demote listings, reduce or eliminate any discounts, and take technical
          and/or legal steps to prevent you from using our Services. We shall be
          the sole arbiter in cases of suspected abuse, fraud or violation of
          the Terms, and any decision we make relating to termination or
          disabling of an Account, or access to or use of a Service, shall be
          final and binding. We also reserve the right to modify, terminate or
          withdraw any Services or any Content at any time without notice. Upon
          termination, we will take such actions are provided in the Terms of
          Service for the applicable Services.
        </p>
      </li>
      <li>
        <p>
          <b>LINKS TO AND FROM THIRD PARTY WEBSITES.</b>
          As a convenience to our users, the websites contain products and
          services offered by, and hypertext links to websites owned or operated
          by, parties other than 12 Triangles Entities (“Linked Sites”). When
          you select these hyperlinks to access Linked Sites, you will leave the
          website operated by the 12 Triangles Entity providing the Services.
          Because we have no control over the content on that Linked Site, we
          are not responsible for the availability of such Linked Site or its
          content. We do not adopt, endorse, nor is it responsible or liable for
          any such Linked Site or its content, including advertising, products
          or materials, on or available through such site or its resources. A
          link to a non-12 Triangles website does not imply or constitute
          sponsorship, endorsement, approval or responsibility for the content,
          or the use of such Linked Site. We reserve the right to terminate any
          hyperlink to a Linked Site at any time without notice.
          <br />
          <br />
          YOUR CORRESPONDENCE AND BUSINESS DEALINGS WITH OTHERS FOUND ON LINKED
          SITES, INCLUDING, WITHOUT LIMITATION, THE PAYMENT AND DELIVERY OF
          PRODUCTS AND SERVICES, AND ANY TERMS, CONDITIONS, WARRANTIES AND
          REPRESENTATIONS ASSOCIATED WITH ANY SUCH DEALINGS, ARE SOLELY BETWEEN
          YOU AND THE THIRD PARTIES. PLEASE NOTE THAT THE USAGE TERMS, PURCHASE
          TERMS AND/OR PRIVACY POLICIES AT LINKED SITES MAY VARY FROM OUR TERMS
          SO PLEASE MAKE SURE TO CHECK THE TERMS AND POLICIES APPLICABLE TO SUCH
          OTHER LINKED SITES AND THEIR PRODUCTS AND SERVICES PRIOR TO USE.
          <br />
          <br />
          Linking to any page on any of the Services is strictly prohibited in
          the absence of our prior written consent. Any website or other device
          that links to the Services is prohibited from (a) replicating Content,
          (b) framing or using border around the Content, (c) implying in any
          fashion that we are associated with that website or is endorsing that
          website or its products, (d) misrepresenting any state of facts,
          including its relationship with us, and (f) using any logo or
          trademark of 12 Triangles (or any of its affiliates) without express
          written permission from 12 Triangles. We may, in our sole discretion,
          block links to the Services and Content without prior notice.
        </p>
      </li>
      <li>
        <p>
          <b>DISPUTE RESOLUTION AND ARBITRATION.</b>
          Please read this section carefully—it affects your rights. By
          accepting this User Agreement, you and each of the 12 Triangles
          Entities agree to the terms and conditions of this dispute resolution
          process and arbitration agreement. That means you are agreeing to
          resolve any and all disputes with us or claims and/or causes of action
          against us (“Claims”) through binding arbitration or small claims
          dispute procedures (unless you opt out) and agreeing to waive your
          rights to a jury trial and to participate in any class action suit.
          Additional terms and conditions governing a dispute between us,
          including disclaimers of certain warranties, limitations of liability,
          your indemnification obligations, and governing law are set out in
          separate sections in this User Agreement. To expedite resolution of
          disputes and control related costs, you and we agree that any
          <b>past, present, or future legal or equitable Claims</b>
          will be resolved as follows.
          <b>
            YOU AND WE EACH AGREE THAT, EXCEPT AS DISCUSSED BELOW, ANY AND ALL
            CLAIMS OR DISPUTES ARISING OUT OF OR RELATED TO THIS USER AGREEMENT,
            ANY TERMS OF SERVICE, THE PRIVACY POLICY, YOUR USE OF ANY SERVICE OR
            WEBSITE, OR YOUR RELATIONSHIP WITH 12 TRIANGLES OR ANY 12 TRIANGLES
            ENTITY WILL BE RESOLVED THROUGH BINDING ARBITRATION OR IN SMALL
            CLAIMS COURT.
          </b>
          You and we each agree that our agreement affects interstate commerce
          so that the Federal Arbitration Act and federal arbitration law, not
          state law, apply and govern the enforceability of this dispute
          resolution process. THERE IS NO JUDGE OR JURY IN ARBITRATION, AND
          COURT REVIEW OF AN ARBITRATION AWARD IS LIMITED. The arbitrator must
          follow this agreement and can award the same damages as a court. Your
          right to opt-out of the Arbitration Agreement. YOU MAY CHOOSE TO
          PURSUE YOUR CLAIM IN COURT AND NOT BY ARBITRATION IF YOU OPT OUT OF
          THIS ARBITRATION AGREEMENT WITHIN 30 DAYS AFTER THE DATE YOU BECOME
          BOUND BY THIS AGREEMENT (the “Opt Out Deadline”). If you do not wish
          to be bound by this Arbitration Agreement, you must timely notify us
          by sending an email to ITGArbitrationOptOut@12Triangles.com and
          providing the following information in your email: (1) your name; (2)
          your address; (3) your phone number, and (4) a clear statement that
          you wish to opt out of this Arbitration Agreement. Any opt-out notice
          received after the Opt Out Deadline will not be valid and you will be
          required to pursue your claim in arbitration or small claims court.
          Informal resolution. We will first try to resolve any Claim
          informally. You and we agree to negotiate to resolve your claim in
          good faith. You agree that you may not start a formal proceeding for
          at least 60 days after you notify us of a Claim in writing, consistent
          with the Notice and Demand requirements below. If you intend to file a
          demand for arbitration, in order to give us an opportunity to
          informally resolve your claim, you must first send us, by certified
          mail, a written Notice of Dispute (“Notice”). The Notice should be
          addressed to 12 Triangles Arbitration, Attn: Legal Department, 11908 S
          111th Ave Circle, Papillion, Nebraska 68046. If you and we do not
          reach an agreement to resolve the Claim within 60 days after the
          Notice is received, or immediately upon the 12 Triangles Entity’s
          denial of your Claims, you or we may start an arbitration by filing a
          Demand for Arbitration (“Demand”), as set forth below, or start small
          claims court proceedings. Formal resolution. If we cannot resolve a
          Claim informally, then either you or we may seek to formally resolve
          any Claim by starting arbitration or small claims proceedings under
          the following agreed procedure and rules. To begin arbitration, you
          must send a Demand to us and to the American Arbitration Association
          (“AAA”). You and we agree that any and all Claims will be submitted to
          final, binding, and confidential arbitration before the AAA under its
          Consumer Arbitration Rules and Commercial Arbitration Rules, as
          applicable to a particular dispute (“AAA Rules”). Where any of the AAA
          rules conflict with the terms of this arbitration agreement, however,
          the terms of our agreement will control. Our agreement to arbitrate is
          retroactive in effect and applies to all Claims regardless of whether
          they accrued before or after the User Agreement and this agreed
          dispute resolution procedure went into effect. Our agreement to submit
          all Claims to arbitration includes all disputes or questions about
          arbitrability, all claims for temporary, injunctive, or declaratory
          relief, and all claims arising under any federal or state statute,
          rule, or regulation. ARBITRATION MEANS THAT YOU AND WE WAIVE OUR RIGHT
          TO A JURY TRIAL. WE BOTH UNDERSTAND THAT ARBITRATION IS A
          DISPUTE-RESOLUTION PROCESS THAT IS DIFFERENT FROM FILING A LAWSUIT IN
          COURT AND THAT BY AGREEING TO SUBMIT ALL CLAIMS TO ARBITRATION, YOU
          AND WE ARE EACH GIVING UP OUR RIGHT TO FILE A LAWSUIT AND OUR RIGHT
          FOR A JURY TO DECIDE OUR CASE. YOU CAN OPT OUT OF THE ARBITRATION BY
          FOLLOWING THE INSTRUCTIONS ABOVE. You and we agree that Claims
          submitted to arbitration will be decided in a single arbitration
          before a single arbitrator. The arbitrator will be selected using the
          following procedure: (1) the AAA will send the parties a list of five
          candidates; (2) if the parties cannot agree to select an arbitrator
          from that list of five, then within 14 days, each party will return
          its list to the AAA striking up to two candidates and ranking the
          remaining candidates by order of preference, (3) the AAA will appoint
          as arbitrator the candidate with the highest preferred ranking on an
          aggregated basis; and (4) if for some reason appointment cannot be
          made under this procedure, the AAA will have discretion to appoint an
          arbitrator of its choosing. We will pay or reimburse all AAA filing,
          administration, and arbitrator fees. If, however, the Arbitrator finds
          that either the substance of your Claim or the relief sought in the
          Demand was frivolous or brought for an improper purpose, then the
          payment of all such fees will be governed by the AAA rules. An
          arbitrator may award on an individual basis any relief that would be
          available in court, including injunctive or declaratory relief and
          attorneys’ fees. For claims under $75,000 as to which you provided
          Notice and negotiated in good faith before starting an arbitration, if
          the arbitrator finds you are the prevailing party in the arbitration,
          then you will be entitled to recover reasonable attorneys’ fees and
          costs. Except for claims determined to be frivolous, we agree not to
          seek recovery of our attorneys’ fees from you even where it might
          otherwise be permitted under applicable law. If the Claims asserted in
          any request or demand for arbitration could have been brought in small
          claims court (e.g., for Claims that do not exceed the jurisdictional
          limit of small claims court), then either you or we may elect to have
          the claims heard in small claims court, rather than in arbitration, at
          any time before the arbitrator is appointed, by notifying the other
          party of that election in writing. Class action waiver. YOU AND WE
          AGREE THAT FOR ANY PROCEEDINGS, WHETHER BROUGHT IN ARBITRATION OR
          COURT, CLAIMS MAY BE BROUGHT ONLY IN AN INDIVIDUAL CAPACITY AND IN THE
          NAME OF AN INDIVIDUAL PERSON OR ENTITY AND THAT CLAIMS MUST PROCEED ON
          AN INDIVIDUAL AND NON-CLASS AND NON-REPRESENTATIVE BASIS. THAT MEANS
          YOU AND WE WAIVE THE RIGHT TO BRING A CLASS ACTION AND CANNOT
          ARBITRATE ANY CLAIM AS A REPRESENTATIVE OR NON-REPRESENTATIVE MEMBER
          OF A CLASS. You and we agree that Claims of two or more persons may
          not be joined or consolidated in the same arbitration unless arising
          from the same transaction. Furthermore, you and we agree that neither
          you nor we may pursue claims in arbitration as a class or collective
          action, private attorney general action, or other representative
          action. The arbitrator shall have the authority to award relief only
          on an individual basis. The AAA Rules for Class Actions will not apply
          to our arbitration. If you opt out of this dispute resolution process
          and arbitration agreement as described above, this class action waiver
          will not apply to you. Neither you, nor any other individual, can be a
          class representative or class member in a class, consolidated, or
          representative proceeding without having complied with the opt out
          requirements above. Jury trial waiver. If a Claim proceeds in court
          rather than arbitration, YOU AND WE EACH STILL WAIVE ANY RIGHT TO A
          JURY TRIAL. Severability. If any part of this dispute resolution
          process and arbitration agreement is held invalid and unenforceable,
          then that part may be severed from the rest of the agreement. You and
          we agree that any ruling by an arbitrator or court allowing any
          claimant to seek relief for other persons in a representative capacity
          would be contrary to your and our intent and would require immediate
          judicial review. If we believe that any Claim you have filed in
          arbitration or in court is inconsistent with the class action waiver,
          then you agree that we may seek an order from a court determining
          whether your claim is within the scope of this class action waiver.
        </p>
      </li>
      <li>
        <p>
          <b>DISCLAIMER OF WARRANTIES.</b>
          THE SERVICES AND ALL CONTENT AND PRODUCTS ARE SUBJECT TO CHANGE AND
          PROVIDED TO YOU ON AN "AS IS" AND “AS AVAILABLE” BASIS WITHOUT ANY
          WARRANTY OF ANY KIND, EITHER EXPRESS OR IMPLIED, INCLUDING, BUT NOT
          LIMITED TO, THE IMPLIED WARRANTIES OF MERCHANTABILITY, FITNESS FOR A
          PARTICULAR PURPOSE, TITLE, OR NON-INFRINGEMENT, AND 12 TRIANGLES AND
          ITS PARENT, AFFILIATES, SUBSIDIARIES, DESIGNEES AND EACH OF THEIR
          RESPECTIVE OFFICERS, DIRECTORS, EMPLOYEES, AGENTS, THIRD–PARTY CONTENT
          PROVIDERS, CONTRACTORS, DISTRIBUTORS, MERCHANTS, SPONSORS, LICENSORS
          AND THE LIKE (COLLECTIVELY, “RELEASED PARTIES”) TO THE FULLEST EXTENT
          PERMITTED BY LAW, EXPRESSLY DISCLAIM ALL SUCH REPRESENTATIONS AND
          WARRANTIES. 12 TRIANGLES RESERVES THE RIGHT TO WITHDRAW OR DELETE ANY
          INFORMATION FROM ITS WEBSITES OR ITS SERVICES AT ANY TIME IN ITS SOLE
          DISCRETION. Without limiting the foregoing, we neither warrant nor
          represent that (i) your use of any Content will not infringe the
          rights of any third parties; (ii) the Content or information contained
          on Linked Sites will be accurate, reliable, complete or up-to-date;
          (iii) Content provided on the Services is applicable to, or
          appropriate for use in, locations outside of the United States; (iv)
          your access to the Services will be secure, uninterrupted or free of
          errors or other harmful components; (v) you will be satisfied with the
          products or services that you purchase from a Linked Site or any
          dealer, retailer, or provider listed on the Service; or (vi) the
          results you may obtain by accessing or using he Services or Content.
          We assume no responsibility or liability arising from any material or
          information that may be provided by any third party. Please note that
          some jurisdictions may not allow the exclusion of implied warranties,
          so some of the above exclusions may not apply to you.
        </p>
      </li>
      <li>
        <p>
          <b>EXCLUSION OF LIABILITY AND RELEASE.</b>
          YOUR USE OF THE SERVICES IS AT YOUR OWN RISK. IN NO EVENT WILL ANY 12
          TRIANGLES ENTITY OR ANY RELEASED PARTIES BE RESPONSIBLE FOR ANY
          POTENTIAL DAMAGES TO YOUR COMPUTER SYSTEM OR LOSS OF DATA THAT RESULTS
          FROM YOUR USE OF THE SERVICES, DOWNLOAD OF ANY CONTENT OR ACCESS TO
          ANY LINKED SITE. TO THE MAXIMUM EXTENT PERMITTED BY APPLICABLE LAW,
          NEITHER 12 TRIANGLES NOR ANY 12 TRIANGLES ENTITY OR OTHER RELEASED
          PARTIES INVOLVED IN CREATING, PRODUCING, OR DELIVERING THE SERVICES OR
          CONTENT, ARE LIABLE FOR ANY DIRECT, INDIRECT, PUNITIVE, INCIDENTAL,
          SPECIAL, CONSEQUENTIAL OR OTHER DAMAGES (INCLUDING, BUT NOT LIMITED
          TO, LOSS OF DATA, LOSS OF MONEY, GOODWILL OR REPUTATION, PROFITS,
          OTHER INTANGIBLE LOSSES) ARISING OUT OF OR IN ANY WAY CONNECTED WITH
          THE USE OR INABILITY OF USE OF THE SERVICES OR CONTENT WHETHER BASED
          ON CONTRACT, TORT, STRICT LIABILITY OR OTHERWISE, EVEN IF ADVISED OF
          THE POSSIBILITY OF ANY SUCH DAMAGES. Please note that some
          jurisdictions may not allow the exclusion of certain damages, so some
          of the above exclusions may not apply to you. If you have a dispute
          with one or more users, you release 12 Triangles (including, our
          parent and affiliates entities, all 12 Triangles Entities, and our and
          their respective and their respective officers, directors, employees,
          agents, contractors, and representatives) from claims, demands and
          damages of every kind and nature, known and unknown, arising out of or
          in any way connected with such disputes. IF ANY PORTION OF THE
          LIMITATION OF LIABILITY IS FOUND TO BE INVALID OR UNENFORCEABLE FOR
          ANY REASON, THEN THE AGGREGATE LIABILITY OF THE RELEASED PARTIES TO
          YOU OR ANY THIRD PARTY SHALL NOT EXCEED THE LESSER OF (A) THE AMOUNT
          OF FEES IN DISPUTE NOT TO EXCEED THE FEES OR CHARGES PAID BY YOU IN
          THE 12 MONTHS PRIOR TO THE ACTION GIVING RISE TO THE LIABILITY FOR THE
          APPLICABLE SERVICES OR (B) ONE HUNDRED DOLLARS ($100). Without
          limiting the generality of the foregoing, you hereby waive the
          provisions and protections of the California Civil Code Section 1542,
          which provides as follows:
          <b>
            A general release does not extend to claims that the creditor or
            releasing party does not know or suspect to exist in his or her
            favor at the time of executing the release and that, if known by him
            or her, would have materially affected his or her settlement with
            the debtor or released party.
          </b>
        </p>
      </li>
      <li>
        <p>
          <b>INDEMINIFICATION BY YOU.</b>
          You agree to indemnify, defend, and hold harmless 12 Triangles, all
          its parent and affiliate entities, Released Parties, and their
          respective officers, directors, employees, agents, and representatives
          from and against any and all claims, losses, damages and costs,
          including reasonable attorneys’ fees and related expenses, arising out
          of or related to (i) breach of any of the Terms by you or anyone
          acting on your behalf; (ii) your activities in connection with use of
          the Services; or (iii) any fraud, misconduct, or violation of
          applicable law in connection with the use of the Services. 12
          Triangles reserves the right, at our own expense, to assume the
          exclusive defense and control of any matter otherwise subject to this
          indemnification by you. You will not enter into any settlement
          agreement which affects the rights of any of the indemnified parties
          or requires the taking of any action by any of them, without our prior
          written approval.
        </p>
      </li>
      <li>
        <p>
          <b>EXPORT CONTROL.</b>
          Software and other materials available through the Services may be
          subject to United States Export Control. The United States Export
          Control laws prohibit the export of certain technical data and
          software to certain territories. No software from the Services may be
          downloaded or exported (i) into any other country to which the United
          States has embargoed goods; or (ii) anyone on the United States
          Treasury Department's list of Specially Designated Nationals or the
          U.S. Commerce Department's Table of Deny Orders. We do not authorize
          the downloading or exportation of any software or technical data from
          the Services to any jurisdiction prohibited by the United States
          Export Laws.
        </p>
      </li>
      <li>
        <p>
          <b>GOVERNING LAW.</b>
          Except as otherwise expressly stated in the Terms of Services for a
          specific Service, these Terms and all claims arising out of or
          relating to this User Agreement, your Account, your relationship with
          12 Triangles or any entity providing Services, and use of the
          Services, will be governed by construed and enforced in accordance
          with the laws of the State of Delaware, without giving effect to any
          principles or conflicts of laws.
        </p>
      </li>
      <li>
        <p>
          <b>NOTICE FOR CALIFORNIA USERS.</b>
          Under California Civil Code Section 1789.3, California users of the
          Services are entitled to the following specific consumer rights
          notice: The Complaint Assistance Unit of the Division of Consumer
          Services of the California Department of Consumer Affairs may be
          contacted in writing at 400 R Street, Suite 1080, Sacramento,
          California 95814, or by telephone at (916) 445-1254 or (800) 952-5210.
        </p>
      </li>
      <li>
        <p>
          <b>GENERAL TERMS; POLICY ENFORCEMENT; WAIVER.</b>
          No agency, partnership, joint venture, employee-employer or
          franchisor-franchisee relationship is intended or created by this User
          Agreement. If any part of the Terms is determined to be invalid or
          unenforceable pursuant to applicable law, then the remainder shall
          continue in effect. The Terms (and applicable policies, guidelines,
          and documentation provided with the Services) represent the entire
          agreement between you and 12 Triangles (and the applicable entity
          providing the Services) with respect to access and use of your Account
          and Services and supersede any prior statements or representations.
          When an issue arises with respect to use of Services, we may consider
          the user's performance history and the specific circumstances in
          applying our policies. We choose, in our sole discretion, how enforce
          these Terms and our policies and guidelines in an effort to do the
          right thing for our users. The foregoing does not limit or impair our
          right to refuse, modify, or terminate all or part of our Services to
          anyone, or to terminate an Account with anyone, for any reason at our
          discretion.
        </p>
      </li>
      <li>
        <p>
          <b>HOW TO CONTACT US.</b>
          <br />
          <br />
          Email:
          <span>
            <a href="mailto:hello@12Triangles.com">hello@12Triangles.com</a>
          </span>
        </p>
      </li>
    </ol>
    <p />
  </div>
</div>
