<script>
  import { Doc, FirebaseApp } from 'sveltefire'
  import { firestore, auth, storage } from '../lib/firebase.js'
  import Ad from '../components/Ad.svelte'

  window.scrollTo(0, 0)
  const fileUrl = window.location.href.split('#')[1]
  let title = capital_letter(fileUrl.replace(/-/g, ' ').replace(/\//g, ' '))
  function capital_letter(str) {
    // Split the input string into an array of words
    str = str.split(' ')

    // Iterate through each word in the array
    for (var i = 0, x = str.length; i < x; i++) {
      // Capitalize the first letter of each word and concatenate it with the rest of the word
      try {
        str[i] = str[i][0].toUpperCase() + str[i].substr(1)
      } catch (error) {}
    }

    // Join the modified array of words back into a string
    return str.join(' ')
  }
</script>

<style>
  .container {
    display: flex;
    flex-direction: column;
    text-align: center;
  }
  h1 {
    text-transform: capitalize;
  }
</style>

<title>{title}</title>
<head>
  <meta property="og:title" content={title} />
  <meta
    property="og:description"
    content="Quickly center grade your sports and trading cards!" />
</head>
<FirebaseApp {auth} {firestore} {storage}>
  <Doc ref="posts{fileUrl}" let:data>
    <div class="container">

      <h1>{title}</h1>
      <img src="data:image/png;base64,{data.headerImage}" alt="" />
      <br />
      <div>{data.intro}</div>
      <br />
      <Ad />
      <div>
        {#each data.cards as card}
          <h2>{JSON.parse(card).t}</h2>
          <img src="data:image/png;base64,{JSON.parse(card).i}" alt="" />
          <br />
          <br />
          <b>{JSON.parse(card).v}</b>
          <br />
          <br />
          <div>
            {@html JSON.parse(card).c}
          </div>

          <br />
          <Ad />
          <br />
        {/each}
      </div>
      <div>{data.trivia}</div>
      <br />
      <Ad />
    </div>
  </Doc>
</FirebaseApp>
