<script>
  import Router, { link } from 'svelte-spa-router'
  import Home from './pages/Home.svelte'
  import Terms from './pages/Terms.svelte'
  import Privacy from './pages/Privacy.svelte'

  import CardGradeInfo from './pages/CardGradeInfo.svelte'
  import MostValuable from './pages/MostValuable.svelte'
  import Analytics from './components/Analytics.svelte'
</script>

<style>
  footer {
    background-color: #021021;
    width: 100%;
    margin-top: 80px;
    /* position: fixed;
    bottom: 0px; */
  }

  .links {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    color: #fff;
  }

  .copyright {
    text-align: center;
    color: white;
    margin: 16px auto;
  }

  a {
    margin: 8px;
    color: #fff !important;
  }

  a:hover {
    color: #0c264c !important;
    text-shadow: none;
  }

  .footer-image {
    max-height: 64px;
    margin: auto;
  }

  .flex-me {
    display: flex;
  }

  .spacer {
    flex-grow: 1;
  }

  :global(body) {
    background-color: #f2f2f2;
    padding: 0px !important;
    color: #021021;
  }

  :global(a) {
    text-decoration: none;
    color: #3c6e71 !important;
  }

  :global(a:hover) {
    text-decoration: none;
    color: #021021 !important;
  }

  :global(a.active) {
    color: #0c264c;
  }

  /* Style for terms, etc. */
  :global(.container) {
    width: auto;
    /* max-width: 90%; */

    margin: 12%;
    padding-bottom: 160px;
  }
  @media (max-width: 1024px) {
    :global(.container) {
      width: auto;
      margin: 12% 4%;
      padding-bottom: 160px;
    }
  }

  :global(.section) {
    line-height: 2;
    margin: 32px auto;
  }
</style>

<Analytics />
<Router
  routes={{ '/': Home, '/blog': Home, '/terms': Terms, '/privacy': Privacy, '/center-grade-measuring': CardGradeInfo, '/most-valuable-1948-bowman-baseball-cards': MostValuable, '*': Home }} />

<footer>
  <br />
  <div class="links">
    <!-- <a use:link href="/">Download App</a> -->
    <!-- | -->
    <a use:link href="/center-grade-measuring">How We Grade Cards</a>
    |
    <a use:link href="/terms">Terms</a>
    |
    <a use:link href="/privacy">Privacy Policy</a>

  </div>
  <br />
  <div class="flex-me">
    <div class="spacer">&nbsp;</div>
    <a href="/">
      <picture>
        <source srcset="assets/bolt64.webp" type="image/webp" />
        <source srcset="assets/bolt64.png" type="image/png" />
        <img
          class="footer-image"
          src="bolt64.png"
          alt="Instant Card Grading"
          width="44px"
          height="64px" />
      </picture>
    </a>
    &nbsp; &nbsp;
    <a
      href="https://12triangles.com"
      alt="12 Triangles"
      aria-label="Visit 12 Triangles">
      <img class="footer-image" src="assets/12TrianglesWhite.svg" alt="" />
    </a>
    <div class="spacer">&nbsp;</div>
  </div>
  <div class="copyright">
    INSTANT CARD GRADING
    <br />
    IS A PRODUCT OF 12 TRIANGLES
  </div>
  <div class="copyright">© 12 Triangles, LLC 2023</div>
  <br />
</footer>
