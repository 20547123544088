<script lang="ts">
  //   import { page } from '$app/stores'

  $: {
    if (typeof gtag !== 'undefined') {
      gtag('config', 'G-XKCSW6DYP4', {
        page_title: document.title,
        page_path: window.location.href,
      })
    }
  }
</script>

<svelte:head>
  <script async src="https://www.googletagmanager.com/gtag/js?id=G-XKCSW6DYP4">

  </script>
  <script>
    window.dataLayer = window.dataLayer || []

    function gtag() {
      dataLayer.push(arguments)
    }

    gtag('js', new Date())
    gtag('config', 'G-XKCSW6DYP4')
  </script>
</svelte:head>
